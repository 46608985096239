import React, {useState, useEffect, Fragment} from "react";
import {
  Container,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionTopBtns from "../Utilities/ActionTopBtns";
import InputTextField from "../../component/InputTextField";
import { useForm } from "react-hook-form";  
import { Link } from 'react-router-dom';
import * as Constants from "../../config/constants";
import { apiCall, manageNetworkError } from "../../assets/js/common";


const ListingPageLayout = ({ pageTitle, children, totalRecords, actionBtnData, filterOpen, setFilterOpen, filter, filterBtnClassName, filterBtnTitle, filterBtnIcon, className, breadcrumbWrapper = true, exportBtnTitle, exportBtn, exportBtnIcon, exportBtnClassName, exportOnClick,importBtnTitle, importBtn, importBtnIcon, importBtnClassName, importOnClick, searchInput,paymentStatusTitle }) => {
  const {
    control,
  } = useForm({
    reValidateMode : 'onChange'
  });
  const { t } = useTranslation();

  const [patientRecordDetails, setPatientRecordDetails] = useState([]);

  useEffect(() => {
    if(searchInput){
      getDropDownDetails();
    }
  } , [])

  const getDropDownDetails = async() => {
    const getRecordDetail = await apiCall('get', Constants.APPOINTMENTS_URL + '/getDropDownDetails?isOnlyPatient=true');

    if (getRecordDetail?.status !== false) {      
      if (getRecordDetail?.data?.data?.patient) {
        setPatientRecordDetails(getRecordDetail?.data?.data?.patient);
      }
    } else {
      manageNetworkError(getRecordDetail);
  }
}

  const handlePatientClick = (patientInfo) => {
    localStorage.setItem('selectedPatient', JSON.stringify(patientInfo));
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPatients = patientRecordDetails.filter((patient) => {
    return (
      patient.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.mobile.toString().includes(searchTerm)
    );
  });

  return (
    <main className={`page-height bg-light-color ${className || '' }`}>	
    {breadcrumbWrapper ?
    	<div className="breadcrumb-wrapper d-flex border-bottom">
        <h1 className="h3 mb-0 me-3 header-title" id="pageTitle">{pageTitle} {totalRecords !== undefined ? <span className="total-record-count">({totalRecords})</span> : ''}</h1>
        {searchInput === true && patientRecordDetails && (
          <div className="position-relative me-2 form-group-mb-0 search-input-div">
            {/* <input type="text"  className="form-control pe-30"/> */}
            <InputTextField control={control} placeholder={t('search-by') + ' ' + t('patient-name') + ' & ' + t('mobile-no')} onFieldChange={handleSearch} name="search_by" />
            <p className="mb-0">
              <i className="fi fi-rr-search calendar-icon"></i>
            </p>
            {
              searchTerm.length >= 3 && (
                <div className="search-input-result-div">
                  {
                    filteredPatients.length > 0 ? (
                      filteredPatients.map((patient, index) => (
                        <Fragment key={index} >
                          <Link to={Constants.APPOINTMENTS_LIST_URL} className="search-input-result-link" onClick={() => handlePatientClick(patient)}>{patient.name} <span>({patient.mobile})</span></Link>
                        </Fragment>
                      ))
                    ) 
                    :
                    <p className="mb-0">No Results Found</p>
                  }
                </div>
              )
            }
          </div>
        )}
        <ActionTopBtns 
          filterBtn={filter} 
          actionTopBtns={actionBtnData} 
          searchInput={searchInput}
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen} 
          filterBtnClassName={filterBtnClassName || ''}
          filterBtnTitle={filterBtnTitle || t('filter')} 
          filterBtnIcon={filterBtnIcon || 'fi fi-rr-settings-sliders'} 
          exportBtnTitle={exportBtnTitle || t('export-excel')} 
          exportBtnIcon={exportBtnIcon || 'fi fi-rr-file-excel'}
          exportBtn={exportBtn} 
          exportOnClick={exportOnClick}
          exportBtnClassName={exportBtnClassName}
          importBtnTitle={importBtnTitle || t('import-excel')} 
          importBtnIcon={importBtnIcon || 'fi fi-rr-cloud-upload'}
          importBtn={importBtn} 
          importOnClick={importOnClick}
          importBtnClassName={importBtnClassName}
        />
        {paymentStatusTitle === true && (
          <div className="header-payment-status-div ms-auto me-0 d-xl-none bg-transparent">
            <h5 className="header-payment-amount due">Due ({t('indian-currency')} 60,000)</h5>
          </div>
        )}
		  </div> : '' }
      <Container fluid className="filter-result-container">
        {children}
      </Container>
    </main>
  );
};
export default ListingPageLayout;