import { useEffect, useCallback } from 'react';
import * as twtConfig from "./config/twt_config";

const useSharedIdleTimer = (onIdle, timeout = twtConfig.LOGOUT_TIME_FOR_NO_ACTIVITY) => {
  const resetTimer = useCallback(() => {
    localStorage.setItem('lastActivityTimestamp', Date.now());
  }, []);

  useEffect(() => {
    // Set up event listeners for user activity
    const events = ['mousedown', 'keydown', 'scroll', 'touchstart', 'click'];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Clean up listeners on unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [resetTimer]);

  useEffect(() => {
    const checkIdleInterval = setInterval(() => {
      const lastActivityTimestamp = localStorage.getItem('lastActivityTimestamp');
      if (lastActivityTimestamp) {
        const idleTime = Date.now() - parseInt(lastActivityTimestamp);
        if (idleTime > timeout) {
          localStorage.removeItem('lastActivityTimestamp')
          onIdle();
        }
      }
    }, 1000);

    return () => {
      clearInterval(checkIdleInterval);
    };
  }, [onIdle, timeout]);

  return resetTimer;
};

export default useSharedIdleTimer;