import React from "react";
import AccessDenied from "../pages/admin/access-denied";

const RouteProtection = ({ children, pageAccess }) => {

  return (
    <React.Fragment>
      {(() => {
        if (pageAccess) {
          return children;
        } else {
          return <AccessDenied />
        }
      })()}
    </React.Fragment>
  );
};

export default RouteProtection;
