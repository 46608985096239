import { DEFAULT_PAGE_INDEX, PER_PAGE } from "../../config/constants";

export const defaultFilterOptions = () => {
    return {
        page : DEFAULT_PAGE_INDEX,
        perPageRecord : PER_PAGE,
        sortColumnName : null,
		sortColumnOrder : null,
        columnSortOrder : null,
    }
};

export const onPageChange = (event , filterData ) => {
	const pageNoSelection = ( event.page >= 1 ? ( event.page + 1 ) : 1 );
	filterData.page = pageNoSelection;
	filterData.perPageRecord = event.rows;
	return filterData;
};

export const onSort = (e , filterData ) => { 
	const sortTypeSelection = ( e.sortOrder === 1 ? 'asc' : 'desc' );
	filterData.sortColumnName = e.sortField;
	filterData.sortColumnOrder = e.sortOrder;
	filterData.columnSortOrder = sortTypeSelection;
	return filterData;
}