import React from "react";
import { Col, Form } from "react-bootstrap";
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { handleInputDrop } from "../assets/js/common";

const InputTextField = ( props = {} ) => {

	const fieldName = props?.name;
	const formErrors = props?.errors;
	const readOnly = props?.readOnly;

	return (
        <Col xs={props?.columnxs} xxl={props?.columnxxl} xl={props?.columnxl} lg={props?.column} className={`${props?.blankLable ? 'blank-label' : ''}`}>
        	<Form.Group className="form-group">
				<Form.Label className="control-label">
				{props?.label}
				{ props?.required ? <span className="text-danger">*</span> : '' }
				{props?.tooltip && (
					<OverlayTrigger
						placement={props?.placement || 'right'}
						overlay={<Tooltip style={{position:"fixed"}} id={`tooltip-${props?.placement || 'right'}`}>{props?.placeholder}</Tooltip>}
					>
						<span className="info-tooltip">
							<i className="fa fa-info-circle" />
						</span>
					</OverlayTrigger>
				)}
				</Form.Label>
        		<Controller
		            name={fieldName}
        			control={props?.control}
					defaultValue={props?.value}
		            rules={ props?.rules || "" }
		            render={({ field, fieldState }) => (
		            	<>	
		                    <InputText
								{...field} 
								name={fieldName}
		                    	placeholder={props?.placeholder} 
		                    	value={field?.value || ''}
		                    	className={ classNames( { 'p-invalid': fieldState.error } , ( props?.classList || '' ) ) } 
		                    	onChange={(e) => {
									if(props?.onFieldChange) {
										props.onFieldChange(e);
									}
									field.onChange(e.target.value);
									if(props?.trigger) {
										props.trigger(fieldName);
									}
								}}
								onKeyPress={props?.onKeyPress}
								onKeyDown={props?.onKeyDown}
								autoComplete={props?.autoComplete || "off"}
								autoFocus={props?.autoFocus}
								maxLength={props?.maxLength}
								max={props?.max}
								onKeyUp={props?.onKeyUp}
								disabled={props?.disabled}
								inputMode={props?.inputMode}
								onBlur={(e) => {
                                    field.onBlur(e);
                                    if (props?.onFieldBlur) {
                                        props.onFieldBlur(e);
                                    }
                                }}
								onFocus={props?.onFocus}
								readOnly={readOnly}
								onDrop={(e) => handleInputDrop(e, field, props.onFieldChange)}
		                    />
	                    </>
		            )}
        		/>
        		{ props?.defaultValidationHtml === false ? '' : ( formErrors && formErrors[fieldName]?.message ) && ( 
					<Form.Label className="invalid-input" htmlFor={fieldName}>{formErrors[fieldName]?.message}</Form.Label> 
        	    )}
        	</Form.Group>
        </Col>
    )
}
export default InputTextField