import React, { useEffect } from "react";
import {handleTableRowClick} from "../../assets/js/common";
import {
  Card,
} from "react-bootstrap";

const TableWrapper = ({children, reactDataTable}) => {

  useEffect(()=> {
    handleTableRowClick('tr');
  })

  return (
    <div className="filter-result-wrapper"> 
      <Card className="shadow-sm">
        <Card.Body className={reactDataTable === true ? 'react-table' : ''}>
          {children}   
        </Card.Body>
      </Card>
    </div>
  );
};
export default TableWrapper;