import {React, lazy, Suspense} from 'react';
import { Route } from "react-router-dom";
import * as Constants from "../config/constants";
import DesignUsers from '../pages/design/admin/users';
const DesignAddUser = lazy(() => import('../pages/design/admin/add-user'));

const DesignPatientLogin = lazy(() => import('../pages/design/admin/patient-login'));
const DesignForgotPassword = lazy(() => import('../pages/design/admin/forgot-password'));
const DesignPatientRegistration = lazy(() => import('../pages/design/admin/patient-registration'));
const DesignReferringDoctors = lazy(() => import('../pages/design/admin/referring-doctors'));
const DesignProvisionalDiagnosis = lazy(() => import('../pages/design/admin/provisional-diagnosis'));
const DesignAdviceTemplates = lazy(() => import('../pages/design/admin/advice-templates'));
const DesignInvestigationTemplates = lazy(() => import('../pages/design/admin/investigation-templates'));
const DesignSession = lazy(() => import('../pages/design/admin/session'));
const DesignSubSessions = lazy(() => import('../pages/design/admin/sub-sessions'));
const DesignAddSubSession = lazy(() => import('../pages/design/admin/add-sub-session'));
const DesignDistricts = lazy(() => import('../pages/design/admin/districts'));
const DesignPrescriptionTemplates = lazy(() => import('../pages/design/admin/prescription-templates'));
const DesignPatients = lazy(() => import('../pages/design/admin/patients'));
const DesignAddPatient = lazy(() => import('../pages/design/admin/add-patient'));
const DesignThankYou = lazy(() => import('../pages/design/admin/thank-you'));
const DesignFirms = lazy(() => import('../pages/design/admin/firms'));
const DesignAddFirm = lazy(() => import('../pages/design/admin/add-firm'));
const DesignAccessDenied = lazy(() => import('../pages/design/admin/access-denied'));
const DesignDrugs = lazy(() => import('../pages/design/admin/drugs'));
const DesignAppointmentsCalendar = lazy(() => import('../pages/design/admin/appointments-calendar'));
const DesignInvestigations = lazy(() => import('../pages/design/admin/investigations'));
const DesignInternalMessaging = lazy(() => import('../pages/design/admin/internal-messaging'));
const DesignAddAccessManagement = lazy(() => import('../pages/design/admin/add-access-management'));
const DesignAccessManagement = lazy(() => import('../pages/design/admin/access-management'));
const DesignAddConsultation = lazy(() => import('../pages/design/admin/add-consultation'));
const DesignRateCards = lazy(() => import('../pages/design/admin/rate-cards'));
const DesignExercise = lazy(() => import('../pages/design/admin/exercise'));
const DesignAddExercise = lazy(() => import('../pages/design/admin/add-exercise'));
const DesignAppointmentsList = lazy(() => import('../pages/design/admin/appointments-list'));
const DesignAccounts = lazy(() => import('../pages/design/admin/accounts'));
const DesignPatientDashboard = lazy(() => import('../pages/design/admin/patient/patient-dashboard'));
const DesignPatientAppointments = lazy(() => import('../pages/design/admin/patient/patient-appointments'));
const DesignPatientPlans = lazy(() => import('../pages/design/admin/patient/patient-plans'));
const DesignPatientKritis = lazy(() => import('../pages/design/admin/patient/patient-kritis'));
const DesignViewPatientProfile = lazy(() => import('../pages/design/admin/patient/view-patient-profile'));
const DesignWelcome = lazy(() => import('../pages/design/admin/welcome'));



const DesignRoutes =[
  <Route key="des-1" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-users'} element={<DesignUsers pageName="Users" />} />,
	<Route key="des-2" path={Constants.ADMIN_PANEL_URL_PREFIX + '/users/design-create'} element={<Suspense><DesignAddUser pageName="Add User" /></Suspense>} />,

  <Route key="des-3" path={'/design-patient-login'} element={<Suspense><DesignPatientLogin pageName="Patient Login" /></Suspense>} />,
  <Route key="des-4" path={'/design-forgot-password'} element={<Suspense><DesignForgotPassword pageName="Forgot Password" /></Suspense>} />,
  <Route key="des-5" path={'/design-patient-registration'} element={<Suspense><DesignPatientRegistration pageName="Patient Registration" /></Suspense>} />,
  <Route key="des-6" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-referring-doctors'} element={<Suspense><DesignReferringDoctors pageName="Referring Doctors" /></Suspense>} />,
  <Route key="des-7" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-provisional-diagnosis'} element={<Suspense><DesignProvisionalDiagnosis pageName="Provisional Diagnosis" /></Suspense>} />,
  <Route key="des-8" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-advice-templates'} element={<Suspense><DesignAdviceTemplates pageName="Advice Templates" /></Suspense>} />,
  <Route key="des-9" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-investigation-templates'} element={<Suspense><DesignInvestigationTemplates pageName="Investigation Templates" /></Suspense>} />,
  <Route key="des-10" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-sessions'} element={<Suspense><DesignSession pageName="Sessions" /></Suspense>} />,
  <Route key="des-11" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-sub-sessions'} element={<Suspense><DesignSubSessions pageName="Sub-Sessions" /></Suspense>} />,
  <Route key="des-12" path={Constants.ADMIN_PANEL_URL_PREFIX + '/sub-sessions/design-create'} element={<Suspense><DesignAddSubSession pageName="Add Sub-Session" /></Suspense>} />,
  <Route key="des-13" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-districts'} element={<Suspense><DesignDistricts pageName="Districts" /></Suspense>} />,
  <Route key="des-14" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-prescription-templates'} element={<Suspense><DesignPrescriptionTemplates pageName="Prescription Templates" /></Suspense>} />,
  <Route key="des-15" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-patients'} element={<Suspense><DesignPatients pageName="Patients" /></Suspense>} />,
  <Route key="des-16" path={Constants.ADMIN_PANEL_URL_PREFIX + '/patients/design-create'} element={<Suspense><DesignAddPatient pageName="Add Patient" /></Suspense>} />,
  <Route key="des-17" path={'/design-thank-you'} element={<Suspense><DesignThankYou pageName="Thank You" /></Suspense>} />,
  <Route key="des-18" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-firms'} element={<Suspense><DesignFirms pageName="Firms" /></Suspense>} />,
  <Route key="des-19" path={Constants.ADMIN_PANEL_URL_PREFIX + '/firms/design-create'} element={<Suspense><DesignAddFirm pageName="Add Firm" /></Suspense>} />,
  <Route key="des-20" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-access-denied'} element={<Suspense><DesignAccessDenied pageName="Access Denied" /></Suspense>} />,
  <Route key="des-21" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-drugs'} element={<Suspense><DesignDrugs pageName="Drugs" /></Suspense>} />,
  <Route key="des-22" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-appointments-calendar'} element={<Suspense><DesignAppointmentsCalendar pageName="Appointments" /></Suspense>} />,
  <Route key="des-23" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-investigations'} element={<Suspense><DesignInvestigations pageName="Investigations" /></Suspense>} />,
  <Route key="des-24" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-internal-messaging'} element={<Suspense><DesignInternalMessaging pageName="Internal Messaging" /></Suspense>} />,
  <Route key="des-25" path={Constants.ADMIN_PANEL_URL_PREFIX + '/access-management/design-create'} element={<Suspense><DesignAddAccessManagement pageName="Add Access Management" /></Suspense>} />,
  <Route key="des-26" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-access-management'} element={<Suspense><DesignAccessManagement pageName="Access Management" /></Suspense>} />,
  <Route key="des-27" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-add-consultation'} element={<Suspense><DesignAddConsultation pageName="Add Consultation" /></Suspense>} />,
  <Route key="des-28" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-rate-cards'} element={<Suspense><DesignRateCards pageName="Rate Cards" /></Suspense>} />,
  <Route key="des-29" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-exercises'} element={<Suspense><DesignExercise pageName="Exercises" /></Suspense>} />,
  <Route key="des-30" path={Constants.ADMIN_PANEL_URL_PREFIX + '/exercises/design-create'} element={<Suspense><DesignAddExercise pageName="Add Exercise" /></Suspense>} />,
  <Route key="des-31" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-appointments-list'} element={<Suspense><DesignAppointmentsList pageName="Appointments List" /></Suspense>} />,
  <Route key="des-32" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-accounts'} element={<Suspense><DesignAccounts pageName="Accounts" /></Suspense>} />,
  <Route key="des-33" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-patient-dashboard'} element={<Suspense><DesignPatientDashboard pageName="Patient Dashboard" /></Suspense>} />,
  <Route key="des-34" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-patient-appointments'} element={<Suspense><DesignPatientAppointments pageName="Appointments" /></Suspense>} />,
  <Route key="des-35" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-patient-plans'} element={<Suspense><DesignPatientPlans pageName="Plans" /></Suspense>} />,
  <Route key="des-36" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-patient-kritis'} element={<Suspense><DesignPatientKritis pageName="Kritis" /></Suspense>} />,
  <Route key="des-37" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-view-patient-profile'} element={<Suspense><DesignViewPatientProfile pageName="View Profile" /></Suspense>} />,
  <Route key="des-38" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-welcome'} element={<Suspense><DesignWelcome pageName="Welcome" /></Suspense>} />,

  
];

export default DesignRoutes;