import { React , useCallback, useEffect, useState } from 'react';
import { Route, Routes , useNavigate } from "react-router-dom";
import { backendRoutes } from './routes/backend-route';
import DesignRoute  from './routes/design-route';
import AdminError404 from './pages/errors/admin/404';
import './assets/js/common.js';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import './assets/css/app.css';
import '@fortawesome/fontawesome-free/css/all.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import useIdleTimer from './useIdleTimer';
import * as Constants from "./config/constants";
import * as twtConfig from "./config/twt_config";  
import RouteProtection from './routes/RouteProtection';
import './assets/css/admin-app.css';
import { useTranslation } from 'react-i18next';
import { apiCall, decode, manageNetworkError } from './assets/js/common.js';

function App() {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const [permissions, setPermissions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const logoutAndNavigate = useCallback(() => {
		navigate(Constants.LOGOUT_URL);
	}, [navigate]);
	
	useIdleTimer(logoutAndNavigate, twtConfig.LOGOUT_TIME_FOR_NO_ACTIVITY);

	const getUserPermissions = async () => {
		setIsLoading(true);
		const response = await apiCall( 'get' , '/getUserPermissions' , null, false);

		if(response?.status !== false) {
			if(response?.data?.status_code === Constants.SUCCESS_API_STATUS_CODE && response?.data?.data) {
				setPermissions(decode(response?.data?.data).split(',') || []);
				localStorage.setItem('user_permission', response?.data?.data);
			}
		} else {
			manageNetworkError(response);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		let user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
		if(user && user.token) {
			getUserPermissions();
		} else {
			setIsLoading(false);
		}
	}, [window?.location?.href]);

  return (
    <div className="App">
		{!isLoading && (
			<Routes>
				{DesignRoute}
				<Route key="page-not-found" path="*" element={<AdminError404 />} />

				{backendRoutes(t, permissions).map(
					({component, path, pageAccess} , index) => (
						<Route 
							key={'backend-' + index}
							path={path}
							element={
								<RouteProtection pageAccess={pageAccess}>
									{component}
								</RouteProtection>
							}
						/>
					)
				)}
			</Routes>
		)}
    </div>
  );
}

export default App;
