import React, {useState} from "react";
import Layout from '../../admin/Layout';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Constants from "../../../config/constants";
import ListingPageLayout from "../../../component/PageComponents/ListingPageLayout";
import FilterWrapper from "../../../component/Utilities/FilterWrapper";
import TableWrapper from "../../../component/Utilities/TableWrapper";
import FilterActionBtns from "../../../component/Utilities/FilterActionBtns";
import InputTextField from "../../../component/InputTextField";
import DropdownField from "../../../component/DropdownField";
import ActionsDiv from "../../../component/ActionsDiv";

import { DataTable } from 'primereact/datatable';
import { getStatusOptions, statusDiv } from "../../../assets/js/common";
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import {
	Row,
} from "react-bootstrap";

export default function DesignSubSessions({pageName}) {
	const { t } = useTranslation();
	const [filterOpen, setFilterOpen] = useState(false);
	const statusOptions = getStatusOptions(t);
	const [first, setFirst] = useState(1);
	const [rows, setRows] = useState(10);

	const updateRecordStatus = (e) => {
		// alertifyConfirm(t('update-status') , t('confirm-status-update-msg' , {action : (e.target.checked ? t('enable') : t('disable'))}) , function () {
		// 		statusUpdate(e.target, moduleUrl, setRenderRequired , {lookup_module_name : backendModule});
		// });
		console.log('hi');
	}

	const genderOptions = [
		{ label : t('male'), value : t('male') },
		{ label : t('female'), value : t('female') },
		{ label : t('other'), value : t('other') },
	]
	const roleOptions = [
		{ label : 'Lorem', value : 'Lorem' },
	]
	const accessOptions = [
		{ label : 'Lorem', value : 'Lorem' },
	]
	const districtStateOptions = [
		{ label : 'Mumbai, Maharashtra', value : 'Mumbai, Maharashtra' },
		{ label : 'Kolkata, West Bengal', value : 'Kolkata, West Bengal'},
		{ label : 'Bangalore, Karnataka', value : 'Bangalore, Karnataka'},
		{ label : 'Ahmedabad, Gujarat', value : 'Ahmedabad, Gujarat'},
	]
	const specializationOptions = [
		{ label : 'Lorem', value : 'Lorem' },
		{ label : 'Lorem1', value : 'Lorem1' },
		{ label : 'Lorem2', value : 'Lorem2' },
		{ label : 'Lorem3', value : 'Lorem3' },
	]
	const verificationOptions = [
		{ label : 'Lorem', value : 'Lorem' },
	]

	const {
		control,
		formState: { errors },
		} = useForm({
			reValidateMode:'onChange'
		});

	const dummyData = [
		{ sr_no: 1, name: 'John Doe', mobile_no: '9876543210', gender:'Male', age: '34', designation: 'Senior', role:'lorem', access:'lorem', last_login: '11-06-2024, 10:02 AM - 192.168.1.1'},
		{ sr_no: 2, name: 'John Doe', mobile_no: '7876543210', gender:'Female', age: '34', designation: 'Senior', role:'lorem', access:'lorem', last_login: '11-06-2024, 10:02 AM - 192.168.1.1'},
	]

	var tableColumns = [];
	tableColumns.push({ id: 'sr_no', name: t('sr-no') , sortable: false , className: 'sr-col', headerTextCenter: true });
	tableColumns.push({ id: 'name', name: t('name'), sortable: true, className: 'table-w-250' });
	tableColumns.push({ id: 'mobile_no', name: t('mobile-no'), sortable: true, className: 'table-w-150' });
	tableColumns.push({ id: 'gender', name: t('gender'), sortable: true, className: 'table-w-100' });
	tableColumns.push({ id: 'age', name: t('age'), sortable: true, className: 'table-w-100' });
	tableColumns.push({ id: 'designation', name: t('designation'), sortable: true, className: 'table-w-200' });
	tableColumns.push({ id: 'role', name: t('role'), sortable: true, className: 'table-w-150' });
	tableColumns.push({ id: 'access', name: t('access'), sortable: true, className: 'table-w-150' });
	tableColumns.push({ id: 'active_inactive', name: t('active-inactive'), sortable: true, className: 'status-col', body : row => statusDiv(row , updateRecordStatus),headerTextCenter: true });
	tableColumns.push({ id: 'last_login', name: t('last-login'), sortable: true, className: 'table-w-270' });
	tableColumns.push({ id: 'action', name: t('actions'), body: <ActionsDiv design={true} /> , className: 'actions-col', headerTextCenter: true });
	const columns = tableColumns;

	const handlePageChange = (e) => {
		setFirst(e?.first);
		setRows(e?.rows);
	}

	const handleFilterField = (e , renderRequired = false) => {
		// setFilterData(filterData => {
		// 		return { ...filterData, [e.target.name]: e.target.value }
		// });
		// if(renderRequired) {
		// 		setRenderRequired(true);
		// }
	}

  return (
    <Layout pageName={pageName}>
		<ListingPageLayout 
			pageTitle={t('users')}
			totalRecords="100"
			actionBtnData={[
				{url: Constants.ADMIN_PANEL_URL_PREFIX + '/users/design-create/', title: t("add"), icon: true, show: true, className:"btn-blur"},
			]}
			filter={true} filterOpen={filterOpen} setFilterOpen={setFilterOpen} 
		>
			<FilterWrapper data={filterOpen}>
				<Row>
					<InputTextField control={control} errors={errors} tooltip={true} column={4} label={t("search-by")} placeholder={t('search-by') + ' ' + t('name') + ', ' + t('mobile-no') + ', ' + t('designation') + ', ' + t('degree')  + ', ' + t('registration-no') }  name="search_by" />
					<DropdownField control={control} errors={errors} placeholder={t('select')} column={2} label={t('gender')}  name="search_gender" options={genderOptions} />

					<DropdownField control={control} errors={errors} placeholder={t('select')}  column={2} label={t('district-state')}  name="search_district_state" options={districtStateOptions} isFilterEnabled />

					<DropdownField control={control} errors={errors} placeholder={t('select')} column={2} label={t('role')}  name="search_role" options={roleOptions}/>

					<DropdownField control={control} errors={errors} placeholder={t('select')} column={2} label={t('access')}  name="search_access" options={accessOptions}/>

					<DropdownField control={control} errors={errors} placeholder={t('select')} column={2} label={t('specialization')}  name="search_specialization" options={specializationOptions}/>

					<DropdownField control={control} errors={errors} placeholder={t('select')} column={2} label={t('verification')}  name="search_verification" options={verificationOptions}/>

					<DropdownField control={control} placeholder={t('select')} column={2} label={t('active-inactive')}  name="search_status" options={statusOptions} onFieldChange={(e) => handleFilterField(e , true)} />
				
					<FilterActionBtns resetOnClick={() => setFilterOpen(!filterOpen)}/>
				</Row>
			</FilterWrapper>

			<TableWrapper reactDataTable={true}>
				<DataTable value={dummyData} showGridlines scrollable scrollHeight={Constants.DATATABLE_HEIGHT} tableStyle={{ minWidth: '50rem' }} emptyMessage={t('no-data-available-in-table')}>
					{columns.map((col, i) => (
						(<Column key={col.id} field={col.id} body={col?.body ? col.body : ''} sortable={col.sortable} headerClassName={col.headerTextCenter === true ? `center-text ${col?.className  ? col.className : ""}` : col?.className  ? col.className : ""} header={col.name} bodyClassName={col?.className ? col.className : ''}  />)
					))}
				</DataTable>
				{<Paginator first={first} rows={rows} totalRecords={50} rowsPerPageOptions={Constants.PER_PAGE_OPTIONS} onPageChange={handlePageChange} />}
			</TableWrapper>
		</ListingPageLayout>
    </Layout>
  );
}