import React from "react";
import { alertifyConfirm, focusOut } from "../assets/js/common";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ActionsDiv = ( props = {} ) => {
    const { t } = useTranslation();
    var designEditCol = (props?.formType === 'form' ? <Link to={void(0)} className="btn btn-sm action-btn edit-btn" title="Edit"><i className="fa-fw fi fi-rr-pencil"></i></Link> :  <button type="button" title="Edit" className="btn btn-sm action-btn edit-btn btn-blur" disabled={props?.editDisabled} ><i className="fi fi-rr-pencil"></i></button> );
    var designDeleteCol = (<Button type="button" title="Delete" variant="" size="sm" className="action-btn delete-btn btn-blur" disabled={props?.deleteDisabled} ><i className="fi fi-rr-trash fa-fw"></i></Button>);

    var editCol = (
        props?.formType === 'form'
        ? 
        <>
        {props?.editDisabled ? (
            <span className="btn btn-sm action-btn edit-btn" title="Edit" style={{ pointerEvents: 'none', opacity: 0.5 }}>
                <i className="fa-fw fi fi-rr-pencil"></i>
            </span>
        ) : (
            <Link to={`${props?.recordUrl}/edit/${props?.recordId}`} className="btn btn-sm action-btn edit-btn" title="Edit">
            <i className="fa-fw fi fi-rr-pencil"></i>
            </Link>
        )}
        </> 
        :  
        <button 
            type="button" 
            title="Edit" 
            className="btn btn-sm action-btn edit-btn btn-blur" 
            onClick={() => {
                if(props?.onEditClick) {
                    props.onEditClick(props?.recordId);
                }
                focusOut();
            }}
            disabled={props?.editDisabled}
        >
            <i className="fi fi-rr-pencil"></i>
        </button> 
    );
    var deleteCol = (
        <Button
            type="button" 
            title="Delete" 
            variant="" 
            size="sm" 
            className="action-btn delete-btn btn-blur" 
            onClick={() => {
                if(props?.onDeleteClick) {
                    alertifyConfirm(t('delete-module', { module: props?.moduleTitle || t('record') }), t('common-module-confirm-msg', { operation: t('delete'), moduleName: props?.moduleTitle || t('record') }), function () {
                        props.onDeleteClick(props?.recordId);
                    });
                }
                focusOut();
            }}
            disabled={props?.deleteDisabled}
        >
            <i className="fi fi-rr-trash fa-fw"></i>
        </Button>
    );

	return (
        <div className="actions-col-div">
            { props?.customColumns ? 
                props.customColumns(
                    ( props?.showEditButton !== false && (props?.design ? designEditCol : editCol) ),
                    ( props?.showDeleteButton !== false && (props?.design ? designDeleteCol : deleteCol) )
                )
                : (
                    <>
                    { props?.showEditButton !== false && (props?.design ? designEditCol : editCol) }
                    { props?.showDeleteButton !== false && (props?.design ? designDeleteCol : deleteCol) }
                    </>
                )
            }
        </div>
    )
}
export default ActionsDiv