var hostName = window.location.hostname;
var protocol = window.location.protocol;
var port = window.location.port;
var siteName = '';
// eslint-disable-next-line
var frontendFolder = '/frontend-react/';
var backendFolder = '/backend/';

export const BASE_PATH = '/';

//encryption keys
export const ENCRYPTION_KEY = "PRAHEAL";
export const SITE_TITLE = 'Praheal';

export const LOGIN_COOKIE_NAME = ENCRYPTION_KEY;
export const ERROR_COOKIE_NAME = ENCRYPTION_KEY + "_ERROR";

// Use Api Base Url & Backend File Url As Per Your Backend Language

// Use In Laravel Or CI API
export const API_BASE_URL = protocol + "//" + hostName + siteName + backendFolder + 'api/';

// Use In Laravel API
export const BACKEND_FILE_URL = protocol + "//" + hostName + siteName + backendFolder + 'storage/app/uploads/';

// Change port as well base on your react app
export const SITE_URL = protocol + "//" + hostName + BASE_PATH;

export const LANGUAGE_FOLDER_URL = SITE_URL; 

//pagination 
export const PER_PAGE = 10;
export const SELECT_ALL_ROWS_TEXT = 'ALL';
export const PER_PAGE_OPTIONS = [10,25,50];
export const DEFAULT_PAGE_INDEX = 1;

//show confirm box 
export const SHOW_CONFIRM_BOX = true;

// Admin Panel Url Prefix 
export const ADMIN_PANEL_URL_PREFIX = '/admin';

//show developer settings
export const SHOW_DEVELOPER_SETTINGS = 1;

// only Admin Panel 
export const ONLY_ADMIN_PANEL = false;

//send OTP
export const SEND_LOGIN_OTP = false;
export const OTP_LENGTH = 6;
export const LOGIN_RESEND_OTP_TIME = 60; //Enter this value in seconds

//Default TWT Admin Email
export const DEFAULT_TWT_ADMIN_EMAIL = ["info@thewildtigers.com"];

//Default TWT Admin Mobile
export const DEFAULT_TWT_ADMIN_MOBILE = ["9999999999"];

export const SHOW_FORGOT_PASSWORD_LINK = true;
export const SHOW_REMEMBER_ME_CHECKBOX = false;
export const REMEMBER_ME_TIME = 3600 //Enter this value in seconds
export const RESEND_OTP_TIMER_KEY = 'resend-otp-time';
export const LOGOUT_TIME_FOR_NO_ACTIVITY = 2400 * 1000; /* give input in milliseconds (ex : 2400 seconds = 2400 * 1000 milliseconds )*/

export const FIRM_MASTER_ID = 1;
export const PRAHEAL_DOMAIN = 'geniusdigiminds.com';