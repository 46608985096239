import React from "react";
import Layout from '../admin/Layout';
import acessIcon from "../../assets/images/access-icon.svg";
import { useTranslation } from "react-i18next";

export default function AccessDenied() {

	const { t } = useTranslation();

  return (
    <Layout pageName={t('access-denied')}>
		<div style={{background: "#f1f1f1"}}>
			<div style={{height: "calc(100vh - 70px)"}}>
				<div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "100%"}}>
					<img src={acessIcon} alt="Access Denied" className="icon" style={{marginBottom: "15px"}} />
					<h1 style={{color: "rgb(33, 37, 41)", letterSpacing: "0", marginBottom: "10px",fontWeight: "600"}}>{t('access-denied')}</h1>
					<p style={{marginBottom: "0", textTransform: "capitalize",fontSize: "16px"}}>{t('access-denied-msg')}</p>
				</div>
			</div>
		</div>
    </Layout>
  );
}